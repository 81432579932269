<template>
<div>

    <section class="bg-primary-3 min-vh-100 jarallax d-flex align-items-center text-light" data-jarallax data-speed="0.7">
      <img src="assets/portalBackground.jpg" alt="Image" class="jarallax-img">
      <div class="container">
       <transition
       mode="in-out"
                  enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="animate__animated "
       >
        <div class="row text-center justify-content-between extendVine" style="margin-top: 0px;">
          <div class="col-lg-6 mb-4" style="margin: 0 auto;">
            <img src="assets/maingLogo.png" alt="Image" class="rounded shadow-lg">
          </div>
            <div class="col-lg-7 col-xl-8" style="margin: 0 auto;">
                <h1 class="h2 mb-3 text-uppercase" style="letter-spacing: 0.255rem;">Inventory Reports</h1>
                <form class="py-2" style="padding: 0px 40px;">
                  
                </form>

                
                
                
            </div> <!-- end col 7 wrappng everything -->
            <div class="col-12" style="margin:0 auto">
                <div class="row justify-content-between">
                      <div class="col-md-3">
                        <button v-if="wineLoading == false" @click="exportWine()" style="border:0px solid black;background-color:transparent;">
                        <div class="boxPortal">
                          <img src="assets/Reports.png" alt="Image" class="p-4 rounded shadow-lg" style="margin-top:-24px;">
                          
                          <h3 class="text-light text-uppercase" style="margin-bottom:-15px;letter-spacing:0.4rem;font-weight:500">Wine</h3>
                        </div>
                        </button>
                        <div v-else style="height:100%;width:100%;display:flex;align-items:center;justify-content:center;">
                          <div class="spinner-border" role="status" style="margin-top:-24px;">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                          
                      </div>
                      <div class="col-md-3">
                        <button v-if="spiritsLoading == false" @click="exportSpirits()" style="border:0px solid black;background-color:transparent;">
                        <div class="boxPortal"><img src="assets/Reports.png" alt="Image" class="p-4 rounded shadow-lg" style="margin-top:-24px;"><h3 class="text-light text-uppercase" style="margin-bottom:-15px;letter-spacing:0.4rem;font-weight:500">Spirits</h3>
                        </div>
                        </button>
                        <div v-else style="height:100%;width:100%;display:flex;align-items:center;justify-content:center;">
                          <div class="spinner-border" role="status" style="margin-top:-24px;">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <button v-if="beerLoading == false" @click="exportBeer()" style="border:0px solid black;background-color:transparent;">
                        <div class="boxPortal"><img src="assets/Reports.png" alt="Image" class="p-4 rounded shadow-lg" style="margin-top:-24px;"><h3 class="text-light text-uppercase" style="margin-bottom:-15px;letter-spacing:0.4rem;font-weight:500">Beer</h3>
                        </div>
                        </button>
                        <div v-else style="height:100%;width:100%;display:flex;align-items:center;justify-content:center;">
                          <div class="spinner-border" role="status" style="margin-top:-24px;">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <button v-if="marketLoading == false" @click="exportMarket()" style="border:0px solid black;background-color:transparent;">
                        <div class="boxPortal"><img src="assets/Reports.png" alt="Image" class="p-4 rounded shadow-lg" style="margin-top:-24px;"><h3 class="text-light text-uppercase" style="margin-bottom:-15px;letter-spacing:0.4rem;font-weight:500">Market</h3>
                        </div>
                        </button>
                        <div v-else style="height:100%;width:100%;display:flex;align-items:center;justify-content:center;">
                          <div class="spinner-border" role="status" style="margin-top:-24px;">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div>
                  </div>
            </div>
                
        </div> <!-- end row wrapping everyting -->
        </transition>
      
              
        </div>

        <!--
        <div class="vineFooter" style="position: absolute;height: 91px;width: 100%;bottom: 0px;display: flex;justify-content: center;align-content: center;">
          <div class="vineFooterInner">
            <a href="http://www.facebook.com/coolvinesnewark">
            <img class="p-2" src="assets/facebookIcon.png" style="height:4.8rem;width:auto"></a>
            <a href="http://www.instagram.com/coolvineshoboken">
            <img class="p-2" src="assets/instagramIcon.png"style="height:4.8rem;width:auto"></a>
          </div>
        </div>  -->
      
    </section>

</div>

  
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      query: '',
      searchResultsVisible: false,
      wineLoading: false,
      spiritsLoading: false,
      beerLoading: false,
      marketLoading: false,
      
    }
  },
  methods: {
    exportWine() {
      this.wineLoading = true;
      axios.get('/api/exportwine', {responseType: 'blob'}).then(response => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fURL = document.createElement('a');
            fURL.href = fileURL;
            fURL.setAttribute('download', 'wine_extract.xlsx');
            document.body.appendChild(fURL);
            fURL.click();

            this.wineLoading = false;
      });
    },
    exportSpirits() {
      this.spiritsLoading = true;
      axios.get('/api/exportspirits', {responseType: 'blob'}).then(response => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fURL = document.createElement('a');
            fURL.href = fileURL;
            fURL.setAttribute('download', 'spirits_extract.xlsx');
            document.body.appendChild(fURL);
            fURL.click();

            this.spiritsLoading = false;
      });
    },
    exportBeer() {
      this.beerLoading = true;
      axios.get('/api/exportsbeer', {responseType: 'blob'}).then(response => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fURL = document.createElement('a');
            fURL.href = fileURL;
            fURL.setAttribute('download', 'beer_extract.xlsx');
            document.body.appendChild(fURL);
            fURL.click();

            this.beerLoading = false;
      });
    },
    exportMarket() {
      this.marketLoading = true;
      axios.get('/api/exportsmarket', {responseType: 'blob'}).then(response => {
            
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fURL = document.createElement('a');
            fURL.href = fileURL;
            fURL.setAttribute('download', 'market_extract.xlsx');
            document.body.appendChild(fURL);
            fURL.click();
            
           
            this.marketLoading = false;
      });
    }
  }
  
}
</script>

<style>
      html {
        scroll-behavior: smooth;
        overflow: hidden;
      }
      .navbar-light .navbar-nav .nav-link {
        color: rgba(73,80,87,.5);
        font-weight: 200;
        font-size: 20px; 
      }
     .boxPortal {
         background-color: rgba(0, 0, 0, 0.24);
         padding-top: 45px;
         padding-bottom: 45px;
         border-radius: 40px;
     }
      .extendVine {
        -webkit-transition: max-height 0.8s;
        -moz-transition: max-height 0.8s;
        transition: max-height 0.8s;
      }
      .searchScroll::-webkit-scrollbar {display:none;}
      .searchScroll {
        -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;
      }
      .fixRouterLink {
        font-weight: 400;

      }
      

      .slideVines-enter-active {
        animation: bounce-in 2s;
      }     
      .slideVines-leave-active {
        animation: bounce-in 0.5s reverse;
      }
      .bounce-transition {
  display: inline-block; /* otherwise scale animation won't work */
}
.bounce-enter {
  animation: bounce-in .5s;
}
.bounce-leave {
  animation: bounce-out .5s;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes bounce-out {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(0);
  }
}
    </style>
